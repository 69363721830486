.Hud {
  position: absolute;
  left: 2px;
  top: 10px;
  image-rendering: pixelated;
  width: 100%;
}

.Hud .Combatant {
  position: relative;
  margin-bottom: 10px;
}

.Hud .Hudaddress {
  position: relative;
  margin-bottom: 13px;
}

.Hud .Combatant_type {
  display: none;
}

.Hudaddress {
  width: 67px;
  position: absolute;
}

.Hudaddress_name {
  position: absolute;
  left: -2px;
  top: -8px;
  white-space: nowrap;
  background: #fff;
  color: #000;
  border-radius: 3px;
  font-size: 6px; /*This is only so small because the component is scaled 2x in battle which is weird */
  padding-left: 2px;
  padding-right: 2px;
  margin: 0;
  border: 1px solid black;
}