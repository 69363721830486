.KeyboardMenu {
  border: 1px solid var(--border-color);
  background: var(--menu-background);
  position: absolute;
  max-height: 100px;
  scroll-behavior: smooth;
  overflow-y: auto;
}

.KeyboardMenu button {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  -webkit-appearance: none;
  border: 0;
  background: none;
  height: 20px;
  cursor: pointer;
  font-family: inherit;
  padding: 0;
  padding-left: 1em;
  font-size: 10px;
}
.KeyboardMenu button:focus {
  background: var(--menu-selected-background);
  outline: 0;
}

.KeyboardMenu .option {
  position: relative;
  padding-bottom: 3px;
}
.KeyboardMenu .option .right {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 10px;
  display: flex;
  align-items: center;
  padding-right: 3px;
}

.DescriptionBox {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: var(--menu-font-color);
  border-top: 1px solid var(--border-color);
  background: var(--menu-background);
  padding: 2px;
}
.DescriptionBox p {
  font-size: 8px;
  margin: 0;
}

/* Battle specific */
.Battle .KeyboardMenu {
  right: 0;
  bottom: 0;
  width: 140px;
}

/* Add custom scrollbar styling */
.KeyboardMenu::-webkit-scrollbar {
  width: 5px;
}

.KeyboardMenu::-webkit-scrollbar-track {
  background: var(--menu-background);
}

.KeyboardMenu::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: 2px;
}
