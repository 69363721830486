.SceneTransition {
  position:absolute;
  left:0;
  top:0;
  right:0;
  bottom:0;
  background: #fff;
  opacity: 0;
  animation: scene-transition-fade-in 1.2s forwards;
}
.SceneTransition.fade-out {
  animation: scene-transition-fade-out 1.2s forwards;
}

@keyframes scene-transition-fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes scene-transition-fade-out {
  from { opacity: 1; }
  to { opacity: 0; }
}