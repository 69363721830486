.MapElement {
  width: 67px;
  right: -1px;
  top: 0px;
  position: absolute;
}

.MapElement_name {
  position: absolute;
  top: -9px;
  left: 18px;
  white-space: nowrap;
  background: #9f6f48;
  color: #fff;
  border-radius: 1px;
  font-size: 8px; /*This is only so small because the component is scaled 2x in battle which is weird */
  padding-left: 2px;
  padding-right: 2px;
  margin: 0;
  border: 1px solid black;
}

.MapElement_name_10 {
  position: absolute;
  top: -9px;
  left: 13px;
  white-space: nowrap;
  background: #9f6f48;
  color: #fff;
  border-radius: 1px;
  font-size: 8px; /*This is only so small because the component is scaled 2x in battle which is weird */
  padding-left: 2px;
  padding-right: 2px;
  margin: 0;
  border: 1px solid black;
}

.MapElement_name_100 {
  position: absolute;
  top: -9px;
  left: 8px;
  white-space: nowrap;
  background: #9f6f48;
  color: #fff;
  border-radius: 1px;
  font-size: 8px; /*This is only so small because the component is scaled 2x in battle which is weird */
  padding-left: 2px;
  padding-right: 2px;
  margin: 0;
  border: 1px solid black;
}

.MapElement_name_1000 {
  position: absolute;
  top: -9px;
  left: 3px;
  white-space: nowrap;
  background: #9f6f48;
  color: #fff;
  border-radius: 1px;
  font-size: 8px; /*This is only so small because the component is scaled 2x in battle which is weird */
  padding-left: 2px;
  padding-right: 2px;
  margin: 0;
  border: 1px solid black;
}

.MapElement_name_merlin {
  position: absolute;
  top: -9px;
  left: -2px;
  white-space: nowrap;
  background: #b223ff;
  color: #fff;
  border-radius: 1px;
  font-size: 8px; /*This is only so small because the component is scaled 2x in battle which is weird */
  padding-left: 2px;
  padding-right: 2px;
  margin: 0;
  border: 1px solid black;
}

.MapElement_name_bob {
  position: absolute;
  top: -9px;
  left: 3px;
  white-space: nowrap;
  background: #FBD623;
  color: #fff;
  border-radius: 1px;
  font-size: 8px; /*This is only so small because the component is scaled 2x in battle which is weird */
  padding-left: 2px;
  padding-right: 2px;
  margin: 0;
  border: 1px solid black;
}

.MapElement_name_botanix {
  position: absolute;
  top: -9px;
  left: 22px;
  white-space: nowrap;
  background: #ffde49;
  color: #000;
  border-radius: 1px;
  font-size: 8px; /*This is only so small because the component is scaled 2x in battle which is weird */
  padding-left: 2px;
  padding-right: 2px;
  margin: 0;
  border: 1px solid black;
}

.MapElement_name_bouncebit {
  position: absolute;
  top: -9px;
  left: 13px;
  white-space: nowrap;
  background: #131618;
  color: #FFF;
  border-radius: 1px;
  font-size: 8px; /*This is only so small because the component is scaled 2x in battle which is weird */
  padding-left: 2px;
  padding-right: 2px;
  margin: 0;
  border: 1px solid black;
}

.Combatant {
  width: 67px;
  height: 14px;
  background: url("/src/images/ui/combatant-plate.png") no-repeat no-repeat;
  position: absolute;
}

.Battle .Combatant {
  transform: scale(2);
  transition: opacity 0.4s;
}
.Battle .Combatant[data-active="false"] {
  opacity: 0;
}
.Battle .Combatant[data-active="true"] {
  opacity: 1;
}

.Combatant[data-team="player"] {
  left: 46px;
  top: 70px;
}
.Combatant[data-team="enemy"] {
  top: 28px;
  right: 77px;
}

.Combatant_character_crop {
  position: absolute;
  bottom: 1px;
  left: 3px;
  width: 22px;
  height: 12px;
  overflow: hidden;
}
.Combatant_character {
  display: block;
  position: absolute;
  left: -11px;
  bottom: -7px;
}
.Battle .Combatant_character_crop {
  display: none;
}

.Combatant_name {
  position: absolute;
  left: -2px;
  top: -8px;
  white-space: nowrap;
  background: var(--menu-font-color);
  color: #fff;
  font-size: 5px; /*This is only so small because the component is scaled 2x in battle which is weird */
  padding-left: 2px;
  padding-right: 2px;
  margin: 0;
}

.Combatant_type {
  position: absolute;
  left: 3px;
  top: -2px;
  width: 16px;
  height: 16px;
}

.Combatant_life-container {
  position: absolute;
  left: 20px;
  top: 4px;
  width: 26px;
  height: 3px;
}
.Combatant_life-container > rect {
  transition: width 0.2s;
}

.Combatant_xp-container {
  position: absolute;
  left: 20px;
  top: 8px;
  width: 26px;
  height: 2px;
}
.Combatant_level {
  position: absolute;
  font-size: 8px;
  right: 2px;
  top: -1px;
  width: 17px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8c594;
  border: 1px solid #a48465;
  margin: 0;
}

.Combatant_status {
  position: absolute;
  font-size: 5px;
  left: 47px;
  bottom: -3px;
  padding-left: 2px;
  padding-right: 2px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  margin: 0;
}
.Combatant_status[data-status="saucy"] {
  color: red;
}
.Combatant_status[data-status="clumsy"] {
  background: #582a79;
}

.Monster {
  position: absolute;
  transform: scale(2);
  transition: opacity 0.4s, transform 0.4s;
  background: url(/src/images/characters/monsters/monster-shadow.png) no-repeat
    no-repeat;
}
.Monster[data-team="player"] {
  bottom: 73px;
  left: 51px;
}
.Monster[data-team="enemy"] {
  top: 47px;
  right: 100px;
}
.Monster[data-active="false"] {
  opacity: 0;
  transform: translate3d(0, 16px, 0) scale(2);
}
.Monster[data-active="true"] {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(2);
}
