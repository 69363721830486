.TitleScreen {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000;
}

.TitleScreen_logo {
  display: block;
  margin: 20px auto 10px auto;
  width: 200px;
  image-rendering: pixelated;
}

.TitleScreen .KeyboardMenu {
  position: relative;
  width: 100px;
  margin: 0 auto;
  background: transparent;
  border: 0;
}
.TitleScreen button {
  background-color: #FBD623;
  justify-content: center;
  padding: 0;
}

.TitleScreen .DescriptionBox {
  background: transparent;
  color: #fff;
  border: 0;
  text-align: center;
}

.fadeIn {
  opacity: 0;
  transition: opacity 2s ease-in-out;
  animation: fadeIn 2s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
