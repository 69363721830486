.Toast {
  position: fixed;
  top: 24px;
  left: 50%;
  transform: translateX(-50%) translateY(100px);
  background: var(--menu-background);
  color: var(--menu-text);
  padding: 6px 12px;
  border: 1px solid var(--menu-border);
  border-radius: 4px;
  font-size: 8px;
  font-weight: bold;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 1000;
}

.Toast--visible {
  transform: translateX(-50%) translateY(0);
  opacity: 1;
}

.Toast_success {
  border-color: #4CAF50;
}

.Toast_error {
  border-color: #f44336;
}

.Toast_text {
  margin: 0;
  text-align: center;
}