.Team {
  display: flex;
  position: absolute;
}

/* In styles/Battle.css */
.Team [data-combatant] {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Team [data-combatant] img.monster-icon {
  width: 100%;
  height: 100%;
  object-fit: none;
  image-rendering: pixelated;
}

.Team[data-team="player"] {
  left: 4px;
  top: 4px;
}

.Team[data-team="enemy"] {
  right: 0px;
  top: 4px;
}

/* Update existing styles for active/dead states */
.Team [data-combatant][data-active="true"] {
  border: 1px solid #f4db4e;
}

.Team [data-combatant][data-dead="true"] {
  opacity: 0.5;
}