:root {
  --border-color: #291d4d;
  --dialog-background: #fff3b4;

  --menu-background: #ffe8d2;
  --menu-border-color: #a48465;
  --menu-font-color: #3a160d;
  --menu-selected-background: #ffb60b;
}

* {
  box-sizing: border-box;
}

body {
  background: #000000;
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-family: "Space Mono", monospace;
}
.how-to-play {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  font-size: 12px;
  color: #fff;
}

.game-container {
  position: relative;
  background: #ffc3a0;
  width: 352px;
  height: 198px;
  margin: 0 auto;
  outline: 1px solid #fff;

  transform: scale(3.5) translateY(40%);
  @media (max-width: 1450px) {
    transform: scale(3) translateY(50%);
  }
  @media (max-width: 1100px) {
    transform: scale(2) translateY(50%);
  }
  @media (max-width: 720px) {
    transform: scale(1) translateY(10%);
  }
}

.game-container canvas {
  image-rendering: pixelated;
}
