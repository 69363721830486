.overlayMenu {
  position: absolute;
  left: 4.5em;
  top: 1em;
  right: 4.5em;
  background: var(--menu-background);
  color: var(--menu-font-color);
  border: 1px solid var(--menu-border-color);
  z-index: 2;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
}

.overlayMenu .KeyboardMenu {
  position: relative;
  border: 0;
}

.overlayMenu h2 {
  font-size: 10px;
  color: var(--menu-font-color);
  margin: 0;
  padding: 2px 0;
  text-align: center;
  border-bottom: 1px solid #a48465;
}

.smallLabel {
  font-size: 8px;
}

.mediumLabel {
  margin-top: 15px;
  font-size: 12px;
  margin-bottom: 15px;
}

.swapContainer {
  margin-bottom: 10px;
}

.SwapMenu {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -30%);
  background: #fff;
  padding: 8px; /* Reduce padding */
  border-radius: 8px; /* Slightly reduce border-radius */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  max-width: 300px; /* Reduce max-width */
  width: 100%;
  text-align: center;
  transform: translate(-50%, -42%) scale(0.8); /* Use scale to shrink the size */
}

.SwapMenu h2 {
  font-size: 14px; /* Reduce font size */
  margin-bottom: 12px; /* Increase margin-bottom for separation */
}

.StakeMenu {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -30%);
  background: #fff;
  padding: 8px; /* Reduce padding */
  border-radius: 8px; /* Slightly reduce border-radius */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  max-width: 300px; /* Reduce max-width */
  width: 100%;
  text-align: center;
  transform: translate(-50%, -42%) scale(0.8); /* Use scale to shrink the size */
}

.StakeMenu h2 {
  font-size: 14px; /* Reduce font size */
  margin-bottom: 12px; /* Increase margin-bottom for separation */
}

.smallLabel {
  font-size: 10px; /* Adjust font size */
  margin-bottom: 5px; /* Increase margin-bottom for separation */
}

.smallTaskLabel {
  font-size: 10px; /* Adjust font size */
}

.taskLabel {
  font-size: 10px; /* Adjust font size */
  width: 50px;
  background-color: #4caf50;
  color: white;
  padding: 5px 10px; /* Adjust padding */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px 0px;
}

input,
select {
  width: calc(100% - 16px); /* Adjust width */
  padding: 6px; /* Reduce padding */
  margin-bottom: 8px; /* Reduce margin-bottom */
  box-sizing: border-box;
}

#outputResult {
  font-size: 14px; /* Adjust font size */
  margin-bottom: 8px; /* Reduce margin-bottom */
}

#craftButton {
  background-color: #4caf50;
  color: white;
  padding: 8px 10px; /* Adjust padding */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 10px; /* Adjust font size */
}

#closeButton {
  background-color: #4caf50;
  color: white;
  padding: 8px 10px; /* Adjust padding */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 10px; /* Adjust font size */
}

.columnContainer {
  display: flex;
  flex-direction: column;
}

.swapSection,
.forSection {
  text-align: left;
  background-color: #f0f0f0; /* Grey background */
  padding: 4px; /* Adjust padding as needed */
  border-radius: 5px; /* Rounded corners */
  margin-bottom: 10px; /* Adjust margin as needed */
}

.taskSection {
  text-align: left;
  background-color: #f0f0f0; /* Grey background */
  padding: 4px; /* Adjust padding as needed */
  border-radius: 5px; /* Rounded corners */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
}


.arrowContainer {
  position: relative;
  margin-bottom: 20px; /* Adjust margin as needed */
}

.spacer {
  margin-top: 5px; /* Adjust margin as needed */
  margin-bottom: 10px; /* Adjust margin as needed */
}

.swapInputContainer {
  display: flex;
}

.arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0;
  border-color: #4caf50 transparent transparent transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.noBorder {
  border: none;
}

.toggleButton {
  margin-right: 10px;
}
.selected {
  background-color: #fff;
  border-radius: 3px;
  border: 2px solid #FBD623;
}

.cancelButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #000;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

